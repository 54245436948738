import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import { useAuthStore } from '#/stores/auth';

export default function createRouter(): VueRouter {
  Vue.use(VueRouter);

  const routes: RouteConfig[] = [
    {
      path: '/ship/:systemName',
      name: 'home',
      meta: { requiresAuth: true },
      props: true,
      component: () => import('~/views/Ship.vue'),
    },
    {
      path: '',
      name: 'cloud-home',
      meta: { requiresAuth: true },
      component: () => import('~/views/Home.vue'),
    },
    {
      path: '/auth/callback',
      name: 'auth-callback',
      meta: { authEndpoint: true },
      component: () => import('~/views/auth/Callback.vue'),
    },
    {
      path: '/auth/login',
      name: 'auth-login',
      meta: { authEndpoint: true },
      component: () => import('~/views/auth/Login.vue'),
    },
  ];

  const router = new VueRouter({
    mode: 'history',
    routes,
  });

  router.beforeEach(async (to, _, next) => {
    // do not load authentication in callback
    if (to.name === 'auth-callback') {
      next();
      return;
    }

    const authStore = useAuthStore();

    try {
      await authStore.loadSession();
    } catch (error) {
      // ignore error as we assume that user is not authenticated and redirect to login page therefor
      return next({ name: 'auth-login' });
    }

    const isAuthenticated = authStore.isAuthenticated;

    if (to.meta?.requiresAuth && !isAuthenticated) {
      next({ name: 'auth-login' });
      return;
    }

    if (to.meta?.authEndpoint && isAuthenticated) {
      // user is already authenticated, to prevent unnecessary authentication redirect to home
      next({ name: 'cloud-home' });
      return;
    }

    next();
  });

  return router;
}
